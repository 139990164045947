<template>
  <div>
    <canvas
      id="canvas-box"
      ref="canvas"
      class="canvas"
      style="border: 1px solid red"
    ></canvas>
    <van-button type="info" @click="printTemplate">打印</van-button>
  </div>
</template>
<script>
import { fabric } from 'fabric'
import request from '../utils/request'
import wx from 'weixin-js-sdk' // 引入微信js-sdk
export default {
  data () {
    return {
      id: '',
      token: '',
      canvasObjects: null
    }
  },
  created () {
    this.canvasObjects = []
    console.log('create')
    // this.token = this.$route.query.token
    this.token =
      this.getQueryString('token') || '3607f4c6-78cc-4d8c-bd1e-d02276077040'
    this.id = this.getQueryString('id') || '38'
    console.log(this.token)
    if (this.id !== '0') {
      this.getCanvasData()
    }
  },
  methods: {
    printTemplate () {
      // 打印
    },
    initCanvas (temp) {
      this.canvas = new fabric.Canvas('canvas-box', {
        includeDefaultValues: false,
        width: temp.template_w * this.getOneMmsPx(),
        height: temp.template_h * this.getOneMmsPx(),
        backgroundColor: '#ffffff'
      })

      if (this.canvasObjects !== null) {
        // 重绘制
        this.canvas.loadFromJSON(
          this.canvasObjects,
          this.canvas.renderAll.bind(this.canvas)
        )
      }
    },
    // 获取每毫米的像素值
    getOneMmsPx () {
      // 创建一个1mm宽的元素插入到页面，然后坐等出结果
      const div = document.createElement('div')
      div.id = 'mm'
      div.style.width = '1mm'
      document.querySelector('body').appendChild(div)
      // 原生方法获取浏览器对元素的计算值
      const mm1 = document.getElementById('mm').getBoundingClientRect()
      console.log(mm1)
      return mm1.width
    },
    getQueryString: function (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null
    },
    async getCanvasData () {
      const res = await request({
        url: '/api/mch/Template/detail',
        method: 'post',
        headers: {
          'X-Access-Token': this.token
        },
        data: {
          id: this.id
        }
      })

      this.canvasObjects = this.htmlDecode(res.data.data.list)

      const temp = res.data.data
      this.initCanvas(temp)
      // this.templateName = temp.template_name
      // this.template_width = temp.template_w
      // this.template_heith = temp.template_h
      // this.template_offx = temp.template_offx
      // this.template_offy = temp.template_offy
    },
    // Decode解码
    htmlDecode: function (text) {
      // 1.首先动态创建一个容器标签元素，如DIV
      let temp = document.createElement('div')
      // 2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text
      // 3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
      const output = temp.innerText || temp.textContent
      temp = null
      return output
    }
  }
}
</script>
<style scoped lang="less"></style>
