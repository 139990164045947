import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Button,
  Tab,
  Tabs,
  Field,
  RadioGroup,
  Radio,
  Picker,
  Popup,
  Slider
} from 'vant'
Vue.config.productionTip = false
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Field)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Slider)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
