<template>
  <div>测试</div>
</template>

<script>
import { fabric } from 'fabric'

export default {
  data () {
    return {}
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.line {
  border-bottom: #777 solid 1px;
}
.control_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  // background-color: #999;
  div {
    padding: 10px 20px;
    margin: 0 100px;
    font-size: 36px;
    border: #777 solid 1px;
  }
}
.head_btn {
  display: flex;
  color: white;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  .tab_btn {
    display: flex;
    .btn {
      color: #9c26e5;
      padding: 10px 60px;
      border: #e53b26 solid 1px;

      .tab_btn .btn:nth-child(1) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .tab_btn .btn:nth-child(2) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .sel_btn {
      background-color: #e53b26;
      color: white;
    }
  }

  .save_btn {
    background-color: #0f1add;
    padding: 10px 50px;
    border-radius: 10px;
  }
}
.content_box {
  color: #333;
  font-size: 36px;
  padding: 10px 20px;

  .template_name {
    display: flex;
    padding: 20px 0;
    .lab_name {
      margin-right: 10px;
    }
    .div {
      width: 150px;
    }
    .div2 {
      width: 100px;
    }

    .lab_unit {
      font-size: 50px;
      color: #0f1add;
      font-weight: 800;
      margin: -10px 20px;
    }
  }
}
.layout_bg {
  height: 600px;
  background-color: #c8c8c8;
}

.content {
  position: relative;
  .key_pop {
    position: absolute;
    z-index: 999;
    top: 200px;
    background-color: white;
  }
}

.content_bg {
  .icon {
    width: 40px;
    height: 40px;
  }

  .content_name {
    font-size: 36px;
    padding: 0 40px;
  }
  .content_cell {
    display: flex;
    margin: 40px 20px;
    div-align: center;

    .manual_input {
      display: flex;
      align-items: center;
      margin: 0 20px;
      .icon {
        width: 40px;
        height: 40px;
        margin: 0 10px;
      }
    }
  }

  .manual_cell {
    display: flex;
    justify-content: center;
  }
  .selData_cell {
    margin: 40px 20px;
    display: flex;
    .input_cell {
      width: 200px;
    }
  }

  .input_cell {
    border: #999 solid 1px;
    border-radius: 6px;

    height: 80px;
    width: 400px;
    input {
      padding: 0 20px;
    }
  }
  .font_cell {
    width: 100px;
  }
  .bold_cell {
    display: flex;
    align-items: center;
    margin: 0 20px;
    image {
      margin-right: 16px;
    }
  }
  .lab_unit {
    font-size: 50px;
    color: #0f1add;
    font-weight: 800;
    margin: -10px 20px;
  }
  .control_btn {
    width: 50px;
    height: 50px;
    border: #777 solid 1px;
    border-radius: 8px;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.layout_bg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #999;

  .canvas {
    border: #333 solid 1px;
    //   height: 100%;
  }
  .option_btns {
    background-color: white;
    width: 100px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .option {
      padding: 0 10px;
      border-bottom: #777 solid 1px;
    }
    image {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
