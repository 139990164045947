import Vue from 'vue'
import VueRouter from 'vue-router'
import PrintView from '../views/PrintView.vue'
import LabTemplate from '../views/LabTemplate.vue'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LabTemplate',
    component: LabTemplate
  },
  {
    path: '/print',
    name: 'PrintView',
    component: PrintView
  }
]

const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  routes
})

export default router
